import React, {
  isValidElement,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import ReactDraggableListView from 'react-drag-listview'
import { useTranslation } from 'react-i18next'

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { PlusCircleOutlined } from '@ant-design/icons'
import { notification, Table } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import { useLocalStorage } from 'beautiful-react-hooks'
import { isObject, merge, pick, round } from 'lodash'
import unionBy from 'lodash/unionBy'

import useToggle from 'hooks/useToggle'
import useWindowDimensions from 'hooks/useWindowDimensions'

import { ExtendedLabel } from 'components/ExtendedLabel'
import ProductDetailPopUp from 'components/ProductInfoPopUp'

import useColumnsV2Hooks from './hooks/useColumnsV2'
import HeaderTitle from './partials/HeaderTitle'
import ResizeableCell from './partials/ResizeableCell'
import type { TableProps } from './types'
import {
  getTextAlignment,
  getTextWidth,
  MULTIPLY_WIDTH as MW,
  renderColumn,
} from './utils'

const ColumnVisibility = dynamic(() => import('./partials/ColumnVisibility'))

/**
 * @deprecated
 * Use from utils instead
 */
export const MULTIPLY_WIDTH = MW

/**
 * @deprecated
 * Use from hooks instead
 */
export const useColumnsV2 = useColumnsV2Hooks

/**
 * @deprecated
 * Use from utils instead
 */
export const get_text_width = getTextWidth

export const components = {
  header: {
    cell: ResizeableCell,
  },
}

/**
 * @deprecated
 * Use from type instead
 */
export type TableCustomProps = TableProps

const TableCustom = React.forwardRef((props: TableProps, ref): JSX.Element => {
  const {
    dataSource,
    translationFile,
    columns: rawColumns,
    selection = true,
    onSelectionChange,
    detailUrl,
    onClickDetail,
    columnHide = false,
    selectionWidth,
    columnKey: definedColumnKey,
    selectionType = 'checkbox',
    showSelect: _showSelect = false,
    onRowClick,
    selectedKeys,
    keepSelectedKeys = false,
    onChangeColumns,
    showPin = true,
    toFixed,
    showProductPopUpBySKU = true,
    enableShowPopupProduct = true,
    columnVisibilityChanger = true,
    rowSelection,
    onBeforeColumnRender,
    disableDragColumns = false,
    excludedVisibilityColumns = [],
    disableLocalStorage = false,
    ...restProps
  } = props

  const columns = onBeforeColumnRender
    ? onBeforeColumnRender(rawColumns)
    : rawColumns

  const { t, i18n } = useTranslation([translationFile, 'common'])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const router = useRouter()
  const localStorageKey = `LDXTABLE-0.0.12-${i18n.language}`
  const columnKey = definedColumnKey || `${router.pathname}`

  const [savedColumns, setSavedColumns] = disableLocalStorage
    ? useState({})
    : useLocalStorage(localStorageKey, {})

  const { width } = useWindowDimensions()

  useImperativeHandle(ref, () => ({
    /**
     * @deprecated use `selectedKeys` instead
     * @returns
     */
    getSelected: () => {
      return selectedRowKeys
    },

    /**
     * @deprecated use `onSelectionChange` instead
     * @returns
     */
    setSelected: (ids: any[]) => {
      return setSelectedRowKeys([...ids])
    },

    /**
     * @deprecated use `selectedKeys` instead
     * @returns
     */
    clearSelected: () => {
      setSelectedRowKeys([])
    },
    handleUpdateSavedColumns,
    getSavedColumns: () => savedColumns[columnKey] || [],
  }))

  /**
   * It takes an array of objects, and returns a new array of objects, with some properties removed
   * @param newSavedColumns - The new columns that the user has selected.
   */
  const handleUpdateSavedColumns = (newSavedColumns) => {
    const updateColumns = [...newSavedColumns].map((column) => {
      return pick(column, [
        'changed',
        'dataIndex',
        'fixed',
        'key',
        'width',
        'sequence',
        'hidden',
        'children',
      ])
    })

    if (definedColumnKey !== 'seasons-planning') {
      const originalColumns = [...(savedColumns[columnKey] || [])]
      const mergedColumns = unionBy(updateColumns, originalColumns, 'dataIndex')

      setSavedColumns({
        ...savedColumns,
        [columnKey]: mergedColumns,
      })
    }
  }

  const removeHidden = (f) => !f.hidden
  const removeDetailButton = (f) => {
    if (f.key === 'select_action' && !isHaveDetail) {
      return false
    }

    return true
  }
  const renderProductPopup = (f) => {
    if (
      ['product_code', 'code'].includes(f.key) &&
      !f.editable &&
      enableShowPopupProduct
    ) {
      return {
        ...f,
        render: (_v, record: any = {}, index) => {
          if (!record.isListField) {
            const productTemplateID = showProductPopUpBySKU
              ? record.product_id?.[0] || record.product_id || record.id
              : record.product_tmpl_id?.[0] ||
                record.product_tmpl_id ||
                record.id

            if (!productTemplateID) {
              if (f.render) {
                return f.render(_v, record)
              }
              return _v
            }

            if (f.render) {
              if (isObject(f.render(_v, record))) {
                return {
                  ...f.render(_v, record),
                  children: (
                    <ProductDetailPopUp
                      productID={productTemplateID}
                      hover
                      placement="right"
                      isSKU={showProductPopUpBySKU}
                      productCode={_v}
                    >
                      <div style={{ width: '100%' }}>
                        {f.render(_v, record).children}
                      </div>
                    </ProductDetailPopUp>
                  ),
                }
              }

              return (
                <ProductDetailPopUp
                  productID={productTemplateID}
                  hover
                  placement="right"
                  isSKU={showProductPopUpBySKU}
                  productCode={_v}
                >
                  <div style={{ width: '100%' }}>{f.render(_v, record)}</div>
                </ProductDetailPopUp>
              )
            }
            return (
              <ProductDetailPopUp
                productID={productTemplateID}
                hover
                placement="right"
                isSKU={showProductPopUpBySKU}
                productCode={_v}
              >
                <div style={{ width: '100%' }}>{_v}</div>
              </ProductDetailPopUp>
            )
          }

          return f.render(_v, record, index)
        },
      }
    }

    return {
      ...f,
      align: f.align || getTextAlignment(f?.format),
      render:
        f?.render ||
        renderColumn({
          column: f,
          detail: {
            url: detailUrl,
            label: t('common:detail'),
          },
          onClickDetail,
          onRowClick,
          toFixed,
        }),
    }
  }

  const mergeNestedColumns = (col, savedCol) => {
    const newCol = merge(col, savedCol)
    if (newCol.children?.length) {
      newCol.children = newCol.children.map((child) =>
        mergeNestedColumns(
          child,
          pick(savedCol, ['changed', 'fixed', 'hidden']),
        ),
      )
    }
    return newCol
  }

  const mergeWithSavedColumn = (item) => {
    const savedColumnIndex = savedColumn?.findIndex((f) => f.key === item.key)
    if (savedColumnIndex !== -1) {
      return mergeNestedColumns(item, savedColumn[savedColumnIndex])
    }

    return item
  }
  const sortColumnAscBySequence = (a, b) => a.sequence - b.sequence

  const isHaveDetail = detailUrl || onClickDetail || onRowClick

  const savedColumn = [...(savedColumns[columnKey] || [])]
  const cols = [...columns]
    .filter(removeHidden)
    .filter(removeDetailButton)
    .map(mergeWithSavedColumn)
    .map((c) => {
      if (c.key === 'select_action') {
        return {
          ...c,
          width: selectionWidth ? round(selectionWidth) : c.width,
        }
      }
      return c
    })

  const mergeColumns = [...cols]
    .filter(removeHidden)
    .filter(removeDetailButton)
    .map(renderProductPopup)
    .sort(sortColumnAscBySequence)

  const handleResize =
    (index: number, parentIndex?: number) =>
    (_e, { size }) => {
      // const iWidth = Math.round(size.width)
      const nextColumns = [...mergeColumns]

      if (String(parentIndex) !== 'undefined') {
        nextColumns[parentIndex].children[index] = {
          ...nextColumns[parentIndex].children[index],
          width: size.width,
          changed: true,
        }

        nextColumns[parentIndex] = {
          ...nextColumns[parentIndex],
          width: nextColumns[parentIndex].children.reduce((prev, curr) => {
            return prev + curr.width
          }, 0),
        }
      } else {
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
          changed: true,
        }

        if (nextColumns[index].children) {
          nextColumns[index].children = [
            ...nextColumns[index].children.map((c) => ({
              ...c,
              width: round(nextColumns[index].width / 2, 0),
            })),
          ]
        }
      }

      handleUpdateSavedColumns(nextColumns)
    }

  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    if (disableDragColumns) return

    const draggedColumns = [...mergeColumns]
    const fixedColumnIndex = draggedColumns.filter((f) => f.fixed).length

    if (toIndex >= 0) {
      const index = isHaveDetail ? fixedColumnIndex : 0
      const item = draggedColumns.splice(fromIndex + index, 1)[0]
      draggedColumns.splice(toIndex + index, 0, {
        ...item,
        changed: true,
      })

      /**
       * TODO:
       * [] Remove remapping on every drag end
       */
      handleUpdateSavedColumns(
        draggedColumns.map((d, i) => ({
          ...d,
          sequence: i + 1,
        })),
      )
    } else {
      notification.error({
        message: t('common:error'),
        description: t('common:unable_to_move_in_or_out_frozen_column'),
      })
    }
  }

  const handleToggleColumnVisibility = (selectedColumnKey: string) => {
    const newColumns = [...mergeColumns]
    const hiddenColumn = newColumns.findIndex(
      (f) => f.key === selectedColumnKey,
    )

    if (hiddenColumn !== -1) {
      newColumns[hiddenColumn].hidden = !newColumns[hiddenColumn].hidden

      if (!newColumns[hiddenColumn].changed) {
        newColumns[hiddenColumn].changed = true
      }
    }

    handleUpdateSavedColumns(newColumns)
  }

  const handleNestedColumnPin = (column: any, fixed: boolean) => {
    for (
      let childIndex = 0;
      childIndex < column.children.length;
      childIndex += 1
    ) {
      column.children[childIndex].fixed = fixed
      if (column.children[childIndex]?.children?.length) {
        handleNestedColumnPin(column.children[childIndex], fixed)
      }
    }
  }

  const calculateNestedColumnWidth = (acc, curr) => {
    if (curr?.children?.length) {
      return acc + curr.children.reduce(calculateNestedColumnWidth, 0)
    }
    return acc + (curr.width || 0)
  }

  const handlePinColumn = (selectedColumnKey: string) => {
    const maxWidth = width * 0.85
    const startIndex = isHaveDetail ? 1 : 0
    const newColumns = [...mergeColumns]
    const fixedColumnIndex = newColumns.findIndex(
      (f) => f.key === selectedColumnKey,
    )
    const fixedColumnLength = newColumns.filter((f) => f.fixed).length

    if (fixedColumnIndex !== -1) {
      if (!newColumns[fixedColumnIndex].fixed) {
        const totalWidthColumn = newColumns
          .slice(0, fixedColumnIndex + 1)
          .reduce(calculateNestedColumnWidth, 0)
        if (
          totalWidthColumn < maxWidth &&
          fixedColumnIndex >= fixedColumnLength
        ) {
          for (let index = startIndex; index <= fixedColumnIndex; index += 1) {
            if (newColumns[index]?.children) {
              handleNestedColumnPin(newColumns[index], true)
            }
            newColumns[index].fixed = true
            newColumns[index].changed = true
          }
        } else {
          notification.error({
            message: t('common:error'),
            description: t('common:current_window_to_small'),
          })
        }
      }

      if (
        newColumns[fixedColumnIndex].fixed &&
        fixedColumnIndex < fixedColumnLength
      ) {
        if (fixedColumnIndex === fixedColumnLength - 1) {
          newColumns[fixedColumnIndex].fixed = false
          if (newColumns[fixedColumnIndex]?.children) {
            handleNestedColumnPin(newColumns[fixedColumnIndex], false)
          }
        } else {
          for (
            let index = startIndex;
            index <= fixedColumnLength - 1;
            index += 1
          ) {
            if (newColumns[index]?.children) {
              handleNestedColumnPin(newColumns[index], false)
            }
            newColumns[index].fixed = false
          }
        }
      }
    }

    handleUpdateSavedColumns(newColumns)
  }

  const titleChecking = (column: any) => {
    if (definedColumnKey === 'seasons-planning') {
      if (column.key === 'action') {
        return ''
      }
    }
    return column.title ? column.title : t(`${translationFile}:${column.key}`)
  }

  const renderChildren = (children, key, index) => {
    return (
      (children &&
        children.map((c, i) => ({
          ...c,
          title: c.title ? (
            <ExtendedLabel title={c.title} requiredMark={c.required} />
          ) : (
            t(`${translationFile}:${key}`)
          ),
          onHeaderCell: (col) => {
            return {
              width: round(Number(col.width), 0),
              onResize: handleResize(i, index),
              fixed: col.fixed,
            }
          },
          onCell: (_, rowIndex) => {
            if (c?.onCell) {
              return {
                'data-cy': `${c?.dataIndex}-${rowIndex}`,
                ...c.onCell(_, rowIndex),
              }
            }
            return {
              'data-cy': `${c?.dataIndex}-${rowIndex}`,
            }
          },
          render: c?.render || renderColumn({ column: c }),
          children: renderChildren(c.children, key, index),
        }))) ||
      []
    )
  }

  const makeResizeable = (column: any, index: any) => {
    const columnEditableWidth = column.width < 150 ? 150 : column.width

    return {
      ...column,
      onCell: (_, rowIndex) => {
        if (column?.onCell) {
          return {
            'data-cy': `${column?.dataIndex}-${rowIndex}`,
            ...column.onCell(_, rowIndex),
          }
        }
        return {
          'data-cy': `${column?.dataIndex}-${rowIndex}`,
        }
      },
      ellipsis: true,
      width: column.editable ? columnEditableWidth : column.width,
      title:
        column.type !== 'button' && isValidElement(column.title) ? (
          column.title
        ) : (
          <HeaderTitle
            headerKey={definedColumnKey}
            dataIndex={column?.dataIndex}
            index={column.key}
            title={titleChecking(column)}
            fixed={column.fixed}
            required={column.required}
            onDelete={handleToggleColumnVisibility}
            canDeleteRow={
              !columnHide && columnVisibilityChanger && column.delete
            }
            onPin={handlePinColumn}
            showPin={column?.showPin ?? showPin}
          />
        ),
      onHeaderCell: (col) => {
        return {
          width: round(Number(col.width), 0),
          onResize: handleResize(index),
          fixed: col.fixed,
        }
      },
      children: renderChildren(column.children, column.key, index),
    }
  }

  const columnWithResizeable = useMemo(
    () => mergeColumns.map(makeResizeable),
    [mergeColumns],
  )

  const handleSelectChange = (selectedRowKeys) => {
    if (onSelectionChange) {
      onSelectionChange(selectedRowKeys)
    }
    setSelectedRowKeys(selectedRowKeys)
  }
  const isColumnHaveChildren = mergeColumns.findIndex((f) => f.children)

  const [showColumnChanger, toggleColumnChanger] = useToggle()

  const defaultRowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys ? selectedKeys : undefined,
    onChange: handleSelectChange,
    preserveSelectedRowKeys: keepSelectedKeys,
    fixed: true,
    columnWidth: selectionWidth || 60,
    // hideSelectAll: !selection,
    type: selectionType,
    selections: columnVisibilityChanger && [
      {
        key: 'column_visibility',
        text: t('common:column_visibility'),
        onSelect: toggleColumnChanger,
      },
    ],
    getCheckboxProps: (record) => ({
      disabled: record?.is_checkable === false,
    }),
    columnTitle: (!selection || selectionType === 'radio') &&
      columnVisibilityChanger && (
        <PlusCircleOutlined
          className="cursor-pointer"
          onClick={toggleColumnChanger}
        />
      ),
    // hideSelectAll: !selection,
    renderCell: (_checked, record, _index, node) => {
      if (!selection || record?.is_checkable === false) {
        return null
      }

      return node
    },
    ...(rowSelection ?? {}),
  }

  /**
   * Ensure and make useEffect deps is comparing easier.
   * This useful to prevent triggering onChangeColumns prop and causing
   * infinite loop if you setState inside the prop (onChangeColumns)
   */
  const columnKeyDeps = columnWithResizeable
    ?.map((i) => i.dataIndex || '')
    .join(',')

  useEffect(() => {
    onChangeColumns?.(columnWithResizeable)
  }, [columnKeyDeps])

  const columnsVisibility = [...columns]
    .filter(removeDetailButton)
    .map(mergeWithSavedColumn)
  const dataSourceColumnVisibility = columnsVisibility
    .filter(
      (f) =>
        f.key !== 'select_action' && !excludedVisibilityColumns.includes(f.key),
    )
    .sort(sortColumnAscBySequence)
    .map((column) => ({
      key: column.key,
      column_name: column.headerName,
      visible: !column.hidden,
      disabled: !column.delete,
    }))

  const handleChangeVisibility = ({
    key,
    visible,
  }: {
    key: string
    visible: boolean
  }) => {
    const newColumns = [...columnsVisibility].map((col) => {
      if (col.fixed === true) {
        return { ...col, fixed: false, changed: true }
      }
      return col
    })
    const hiddenColumn = newColumns.findIndex((f) => f.key === key)

    if (hiddenColumn !== -1) {
      newColumns[hiddenColumn].hidden = !visible

      if (!newColumns[hiddenColumn].changed) {
        newColumns[hiddenColumn].changed = true
      }
    }

    handleUpdateSavedColumns(newColumns)
  }

  useEffect(() => {
    if (disableLocalStorage && props.key) {
      setSavedColumns({})
    }
  }, [props.key, disableLocalStorage])

  //  console.dir(dataSource)

  return (
    <React.Fragment>
      <ReactDraggableListView.DragColumn
        onDragEnd={handleDragEnd}
        nodeSelector="th"
        handleSelector="div"
        ignoreSelector="span.react-resizable-handle,.ant-table-cell-fix-right,.ant-table-cell-fix-left,div.TableHeaderTitleFixedContainer"
      >
        <Table
          rowKey={'id'}
          size="small"
          bordered
          dataSource={dataSource}
          rowSelection={!columnHide && defaultRowSelection}
          components={components}
          columns={columnWithResizeable}
          scroll={{
            // x: 'max-content',
            x: dataSource?.length === 0 ? '100%' : 'max-content',
            /**
             * FIXME:
             * This is a hack to fix the issue of the table being scrollable
             * https://l-dx.slack.com/archives/C028VGY6YV9/p1646282991891749
             */
            // y: window.screen.availHeight
          }}
          showSorterTooltip={false}
          sortDirections={['descend', 'ascend']}
          pagination={false}
          className="LDXTable"
          rowClassName={() => 'editable-row'}
          locale={{
            emptyText: ' ',
          }}
          {...restProps}
          summary={(data) =>
            restProps &&
            restProps.summary &&
            restProps?.summary(data, dataSourceColumnVisibility)
          }
        />

        <style jsx global>
          {`
            .LDXTable .ant-table-selection-column {
              padding: 0 !important;
            }

            .ant-table-selection-column {
              // background-color: rgb(144, 144, 144) !important;
              border-right: none !important;
            }

            .ant-table-cell {
              // border-right: 1px solid #fff !important;
            }
            .ant-table-column-sorters {
              ${definedColumnKey === 'seasons-planning'
                ? 'text-align: center;'
                : ''}
            }

            .LDXTable .ColumnVisibility {
              border-right: 1px solid #fff;
              background-color: rgb(144, 144, 144);
              cursor: pointer;
              padding: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .LDXTable .column-selection {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              background-color: #003288;
              height: ${isColumnHaveChildren !== -1 ? '91px' : 'auto'};
            }

            .ant-table-column-title {
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -khtml-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }

            th.ant-table-selection-column {
              background-color: rgb(144, 144, 144) !important;
            }

            .react-resizable {
              position: relative;
              background-clip: padding-box;
            }

            .react-resizable-handle {
              position: absolute;
              width: 15px;
              height: 100%;
              bottom: 0;
              right: -5px;
              cursor: col-resize;
              z-index: 1;
            }

            .editable-cell {
              position: relative;
            }

            .editable-cell-value-wrap {
              padding: 5px 12px;
              cursor: pointer;
              ${definedColumnKey === 'seasons-planning' ? `height: 30px;` : ''};
            }

            .ant-input {
              ${definedColumnKey === 'seasons-planning'
                ? `text-align: center;`
                : ''};
            }

            .editable-row:hover .editable-cell-value-wrap {
              padding: 4px 11px;
              border: 1px solid #d9d9d9;
              border-radius: 2px;
            }

            .ant-table-column-sorter {
              display: ${definedColumnKey === 'seasons-planning'
                ? 'none'
                : 'block'};
            }
            .ant-table-tbody .editable-row .ant-table-cell-ellipsis {
              ${definedColumnKey === 'seasons-planning'
                ? 'text-align: center !important;'
                : ''};
            }

            //
            // Style for horizontal scroll bar
            //

            .ant-table-sticky-scroll {
              height: 35px;
            }

            .ant-table-sticky-scroll-bar {
              // height: 15px;
              border-radius: 8px;
            }

            /**
             * FIXME:
             * This is a hack to fix the issue of the table being scrollable
             * https://l-dx.slack.com/archives/C028VGY6YV9/p1646282991891749
             */
            // disabled browser scrollbar on virtual table
            // body::-webkit-scrollbar {
            //   width: 0;
            //   background: transparent;
            // }
            // body::-webkit-scrollbar-thumb {
            //   background: #ff0000;
            // }
          `}
        </style>
      </ReactDraggableListView.DragColumn>

      {columnVisibilityChanger && (
        <ColumnVisibility
          visible={showColumnChanger}
          dataSource={dataSourceColumnVisibility}
          onChangeVisibility={handleChangeVisibility}
          onClose={toggleColumnChanger}
        />
      )}
    </React.Fragment>
  )
})

const TableExtended = React.memo(TableCustom)
export default TableExtended
