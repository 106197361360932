import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { useRouter } from 'next/router'

import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import jaJP from 'antd/lib/locale/ja_JP'
import { setLocale } from 'yup'

import parseJWT from 'helpers/parseJWT'
import PUBLIC_PAGES from 'utils/constants/page.constant'
import { ClosingProcessSettingProvider } from 'views/InventoryControl/ClosingProcessSetting/context/ClosingProcessSettingContext'

import 'layouts/i18n'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const Provider: React.FC = ({ children }): JSX.Element => {
  const router = useRouter()
  const { i18n, t, ready } = useTranslation('common')

  useEffect(() => {
    if (router.isReady) {
      const keyPin = localStorage.getItem('key-pin')
      const refreshToken = localStorage.getItem('refresh-token')
      const parsedJwt = parseJWT(refreshToken)

      if (!parsedJwt && !keyPin && !PUBLIC_PAGES.includes(router.pathname)) {
        window.location.replace(
          `/?prevPage=${window.location.pathname}${window.location.search}`,
        )
      }
    }
  }, [router.pathname, router.isReady])

  useEffect(() => {
    if (ready) {
      setLocale({
        mixed: {
          required: t('common:error_required'),
        },
        string: {
          email: t('common:error_email'),
        },
      })
    }
  }, [ready])

  const antdLocale = useMemo(() => {
    if (ready) {
      if (i18n.language?.includes('en')) {
        return enUS
      }
      return {
        ...jaJP,
        Pagination: {
          ...jaJP.Pagination,
          // items_per_page: '/ 件',
          items_per_page: '件',
        },
        Table: {
          emptyText: () => <div />,
        },
      }
    }
  }, [i18n.language, ready])

  const validateMessages = {
    required: t('common:error_required'),
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        locale={antdLocale}
        input={{ autoComplete: 'off' }}
        form={{ validateMessages }}
      >
        <ClosingProcessSettingProvider>
          {children}
        </ClosingProcessSettingProvider>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      {/* <AutoLogout /> */}
    </QueryClientProvider>
  )
}

export default Provider
